import(/* webpackMode: "eager", webpackExports: ["Heading","BodyText"] */ "__barrel_optimize__?names=BodyText,Heading!=!/vercel/path1/node_modules/@aidnas/design-system/dist/esm/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Button","KeyIcon"] */ "__barrel_optimize__?names=Button,KeyIcon!=!/vercel/path1/node_modules/@aidnas/design-system/dist/esm/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Display"] */ "__barrel_optimize__?names=Display!=!/vercel/path1/node_modules/@aidnas/design-system/dist/esm/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Paragraph"] */ "__barrel_optimize__?names=Paragraph!=!/vercel/path1/node_modules/@aidnas/design-system/dist/esm/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/brand/components/Background/Styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/brand/components/Link/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/brand/components/Login/Styles.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path1/brand/components/Sections/Styles.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/image-component.js");
